import {
    GET_CAREERS_LIST,
    ADD_CAREERS,
    DELETE_CAREERS,
    UPDATE_CAREERS,
    GET_CAREERS_GALLERY_LIST,
    ADD_CAREERS_GALLERY,
    DELETE_CAREERS_GALLERY,
    GET_JOB_APPLICATIONS
} from '../actions/CareersActions'

const initialState = {
    CareerList: [],
    Gallery: [],
    JobApplications : []
}

const CareerReducer = function (state = initialState, action) {    
    console.log("action", action);
    switch (action.type) {
        case GET_CAREERS_LIST: {            
            return {
                ...state,
                CareerList: [...action.payload],
            }
        }   
        case ADD_CAREERS: {                  
            state.CareerList.push(action.payload);            
            return {
                ...state                
            }            
        }  
        case DELETE_CAREERS: {
            const index = state.CareerList.findIndex((item) => {
                return item.id == action.id
            })
            if(index >= 0){
                state.CareerList.splice(index, 1);
            }  
            return {
                ...state                
            }            
        }       
        case UPDATE_CAREERS: {
            const index = state.CareerList.findIndex((item) => {
                return item.id == action.id
            })
            console.log("indexe", index)
            console.log("before splice", state.CareerList)
            if(index >= 0){
                state.CareerList.splice(index, 1);
            }  
            console.log("after splice", state.CareerList)
            state.CareerList.push(action.payload); 
            console.log("after push", state.CareerList)
            return {
                ...state                
            }            
        }
        case GET_CAREERS_GALLERY_LIST: {            
            return {
                ...state,
                Gallery: [...action.payload],
            }
        }   
        case ADD_CAREERS_GALLERY: {   
            console.log("action.payload", action.payload);
            action.payload.map((item) => {
                state.Gallery.push(item);            
            })
            return {
                ...state                
            }            
        }  
        case DELETE_CAREERS_GALLERY: {
            const index = state.Gallery.findIndex((item) => {
                return item.id == action.id
            })
            if(index >= 0){
                state.Gallery.splice(index, 1);
            }  
            return {
                ...state                
            }            
        }      
        case GET_JOB_APPLICATIONS: {            
            return {
                ...state,
                JobApplications: [...action.payload],
            }
        }  
           
        default: {
            return {
                ...state,
            }
        }
    }
}

export default CareerReducer
