import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios'
import CONFIG from "../../../config.json";
export const GET_BLOG_CATEGORY = 'GET_BLOG_CATEGORY'
export const ADD_BLOG_CATEGORY = 'ADD_BLOG_CATEGORY'
export const DELETE_BLOG_CATEGORY = 'DELETE_BLOG_CATEGORY'
export const UPDATE_BLOG_CATEGORY = 'UPDATE_BLOG_CATEGORY'
// POST

export const GET_BLOG_POST = 'GET_BLOG_POST'
export const ADD_BLOG_POST = 'ADD_BLOG_POST'
export const DELETE_BLOG_POST = 'DELETE_BLOG_POST'
export const UPDATE_BLOG_POST = 'UPDATE_BLOG_POST'

export const getCategory = () => (dispatch) => {
    axios.get(CONFIG.SERVER_URL + 'category').then((res) => {
        dispatch({
            type: GET_BLOG_CATEGORY,
            payload: JSON.parse(res.data.data),
        })
    })

}

export const addCategory = (data) => (dispatch) => {
    const formData = new FormData();

    // Update the formData object 
    console.log('getData', data)

    if (data.file) {
        formData.append(
            "file",
            data.file,
            data.file.name,
        );
    }
    formData.append(
        "title",
        data.title
    );
    formData.append(
        "meta_title",
        data.meta_title
    );
    formData.append(
        "description",
        data.description
    );
    
    // if (data.parent) {
    //     formData.append(
    //         "parent",
    //         data.parent
    //     );
    // }    
    formData.append(
        "archived",
        data.archived
    ); 
    formData.append(
        "status",
        data.status
    );
  

    axios.post(CONFIG.SERVER_URL + 'category/add', formData).then((res) => {
        if (res.data.status == true) {
            toast.success(res.data.msg);
        } else {
            toast.error(res.data.msg);
        }
        dispatch({
            type: ADD_BLOG_CATEGORY,
            payload: res.data.data,
        })
    }).catch((error) => {
        toast.error("Something went wrong");
    })
}

export const deleteCategory = (id) => (dispatch) => {

    axios.post(CONFIG.SERVER_URL + 'category/delete/' + id).then((res) => {

        if (res.data.status == true) {
            toast.success(res.data.msg);
        } else {
            toast.error(res.data.msg);
        }
        // axios.post(CONFIG.SERVER_URL+'leadership/delete/1000').then((res) => {
        dispatch({
            type: DELETE_BLOG_CATEGORY,
            payload: res.data,
            id
        })
    }).catch((error) => {
        toast.error("Something went wrong");
    })
}
export const updateCategory = (data, id) => (dispatch) => {
    const formData = new FormData();

    // Update the formData object 
    
    if (data.file) {
        formData.append(
            "file",
            data.file,
            data.file.name,
        );
    }
    formData.append(
        "title",
        data.title
    );
    formData.append(
        "meta_title",
        data.meta_title
    );
    formData.append(
        "description",
        data.description
    );
    
    // if (data.parent) {
    //     formData.append(
    //         "parent",
    //         data.parent
    //     );
    // }    
    formData.append(
        "archived",
        data.archived
    ); 
    formData.append(
        "status",
        data.status
    );
  
    axios.post(CONFIG.SERVER_URL + 'category/update/' + id, formData).then((res) => {
        if (res.data.status == true) {
            toast.success(res.data.msg);
        } else {
            toast.error(res.data.msg);
        }
        // axios.post(CONFIG.SERVER_URL+'leadership/delete/1000').then((res) => {
        dispatch({
            type: UPDATE_BLOG_CATEGORY,
            payload: res.data.data,
            id
        })
    }).catch((error) => {
        toast.error("Something went wrong");
    })
}


export const getPost = () => (dispatch) => {
    axios.get(CONFIG.SERVER_URL + 'post').then((res) => {
        dispatch({
            type: GET_BLOG_POST,
            payload: JSON.parse(res.data.data),
        })
    })

}

export const addPost = (data) => (dispatch) => {
    const formData = new FormData();

    // Update the formData object 
    console.log('getData', data)

    if (data.file) {
        formData.append(
            "file",
            data.file,
            data.file.name,
        );
    }
    formData.append(
        "title",
        data.title
    );
    formData.append(
        "meta_title",
        data.meta_title
    );
    formData.append(
        "description",
        data.description
    );
    
    if (data.category) {
        formData.append(
            "category",
            data.category
        );
    }    
    formData.append(
        "archived",
        data.archived
    ); 
    formData.append(
        "status",
        data.status
    );
    formData.append(
        "content",
        data.content
    );
    formData.append(
        "alt_tag",
        data.alt_tag
    );
    
    axios.post(CONFIG.SERVER_URL + 'post/add', formData).then((res) => {
        if (res.data.status == true) {
            toast.success(res.data.msg);
        } else {
            toast.error(res.data.msg);
        }
        dispatch({
            type: ADD_BLOG_POST,
            payload: res.data.data,
        })
    }).catch((error) => {
        toast.error("Something went wrong");
    })
}

export const deletePost = (id) => (dispatch) => {

    axios.post(CONFIG.SERVER_URL + 'post/delete/' + id).then((res) => {

        if (res.data.status == true) {
            toast.success(res.data.msg);
        } else {
            toast.error(res.data.msg);
        }
        // axios.post(CONFIG.SERVER_URL+'leadership/delete/1000').then((res) => {
        dispatch({
            type: DELETE_BLOG_POST,
            payload: res.data,
            id
        })
    }).catch((error) => {
        toast.error("Something went wrong");
    })
}
export const updatePost = (data, id) => (dispatch) => {
    const formData = new FormData();

    // Update the formData object 
    
    if (data.file) {
        formData.append(
            "file",
            data.file,
            data.file.name,
        );
    }
    formData.append(
        "title",
        data.title
    );
    formData.append(
        "meta_title",
        data.meta_title
    );
    formData.append(
        "description",
        data.description
    );
    
    if (data.category) {
        formData.append(
            "category",
            data.category
        );
    }    
    formData.append(
        "archived",
        data.archived
    ); 
    formData.append(
        "status",
        data.status
    );
    formData.append(
        "content",
        data.content
    );
    formData.append(
        "alt_tag",
        data.alt_tag
    );
    
    axios.post(CONFIG.SERVER_URL + 'post/update/' + id, formData).then((res) => {
        if (res.data.status == true) {
            toast.success(res.data.msg);
        } else {
            toast.error(res.data.msg);
        }
        // axios.post(CONFIG.SERVER_URL+'leadership/delete/1000').then((res) => {
        dispatch({
            type: UPDATE_BLOG_POST,
            payload: res.data.data,
            id
        })
    }).catch((error) => {
        toast.error("Something went wrong");
    })
}
