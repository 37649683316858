import {
    GET_INVOICE_LIST,
    ADD_INVOICE,
    DELETE_INVOICE,
    UPDATE_INVOICE,
    UPDATE_CONVERSION_RATE,
    GET_CONVERSION
 
} from '../actions/InvoiceActions'

const initialState = {
    InvoiceList: [],    
    Conversions: [],    
}

const CareerReducer = function (state = initialState, action) {    
    console.log("action", action);
    switch (action.type) {
        case GET_INVOICE_LIST: {            
            return {
                ...state,
                InvoiceList: [...action.payload],
            }
        }   
        case ADD_INVOICE: {                  
            state.InvoiceList.push(action.payload);            
            return {
                ...state                
            }            
        }  
        case DELETE_INVOICE: {
            const index = state.InvoiceList.findIndex((item) => {
                return item.id == action.id
            })
            if(index >= 0){
                state.InvoiceList.splice(index, 1);
            }  
            return {
                ...state                
            }            
        }       
        case UPDATE_INVOICE: {
            const index = state.InvoiceList.findIndex((item) => {
                return item.id == action.id
            })
            console.log("indexe", index)
            console.log("before splice", state.InvoiceList)
            if(index >= 0){
                state.InvoiceList.splice(index, 1);
            }  
            console.log("after splice", state.InvoiceList)
            state.InvoiceList.push(action.payload); 
            console.log("after push", state.InvoiceList)
            return {
                ...state                
            }            
        }
        case GET_CONVERSION: {            
            return {
                ...state,
                Conversions: [action.payload],
            }
           
        } 
        case UPDATE_CONVERSION_RATE :{

           
           
           
                state.Conversions.splice(0, state.Conversions.length);
           
           
            state.Conversions.push(action.payload); 
          
            return {
                ...state                
            }    
        }
               
        default: {
            return {
                ...state,
            }
        }
    }
}

export default CareerReducer
