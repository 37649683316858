import React from 'react';
const servicesRoutes =[
    
    {
        path: '/services/add',
        component: React.lazy(() => import('./AddService')),
    },
    {
        path: '/services/items/add',
        component: React.lazy(() => import('./AddItems')),
    },
    {
        path: '/services/content/add',
        component: React.lazy(() => import('./serviceItems/AddService')),
    },
    {
        path: '/services/content',
        component: React.lazy(() => import('./serviceItems/Index')),
    },
    {
        path: '/services/items',
        component: React.lazy(() => import('./IndexItem')),
    },
    {
        path: '/services',
        component: React.lazy(() => import('./Index')),
    },
]

export default servicesRoutes