import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios'
import CONFIG from "../../../config.json";
export const GET_LIST_TESTIMONIAL = 'GET_LIST_TESTIMONIAL'
export const ADD_ITEM_TESTIMONIAL = 'ADD_ITEM_TESTIMONIAL'
export const DELETE_ITEM_TESTIMONIAL = 'DELETE_ITEM_TESTIMONIAL'
export const UPDATE_ITEM_TESTIMONIAL = 'UPDATE_ITEM_TESTIMONIAL'
export const getData = () => (dispatch) => {
    axios.get(CONFIG.SERVER_URL+'testimonials').then((res) => {
        dispatch({
            type: GET_LIST_TESTIMONIAL,
            payload: JSON.parse(res.data.data),
        })
    })

}

export const addData = (data) => (dispatch) => {
    const formData = new FormData();

    // Update the formData object 
    console.log('getData', data)
    
    formData.append(
        "name",
        data.name
    );
    formData.append(
        "testimonials",
        data.testimonials
    );
    formData.append(
        "location",
        data.location
    );
    formData.append(
        "status",
        data.status
    );

    
    axios.post(CONFIG.SERVER_URL+'testimonials/add', formData).then((res) => {
        if(res.data.status == true){
            toast.success(res.data.msg);
        }else{
            toast.error(res.data.msg);
        }
        dispatch({
            type: ADD_ITEM_TESTIMONIAL,
            payload: res.data.data,
        })
    }).catch((error)=> {
        toast.error("Something went wrong");
    })
}

export const deleteData = (id) => (dispatch) => {
    
    axios.post(CONFIG.SERVER_URL+'testimonials/delete/'+id).then((res) => {

        if(res.data.status == true){
            toast.success(res.data.msg);
        }else{
            toast.error(res.data.msg);
        }
        // axios.post(CONFIG.SERVER_URL+'leadership/delete/1000').then((res) => {
        dispatch({
            type: DELETE_ITEM_TESTIMONIAL,
            payload: res.data,
            id
        })
    }).catch((error)=> {
        toast.error("Something went wrong");
    })
}
export const updateData = (data, id) => (dispatch) => {
    const formData = new FormData();

    // Update the formData object 
    formData.append(
        "name",
        data.name
    );
    formData.append(
        "testimonials",
        data.testimonials
    );
    formData.append(
        "location",
        data.location
    );
    formData.append(
        "status",
        data.status
    );
    
    formData.append(
        "is_home",
        data.is_home
    );
    axios.post(CONFIG.SERVER_URL+'testimonials/update/'+id, formData).then((res) => {
        if(res.data.status == true){
            toast.success(res.data.msg);
        }else{
            toast.error(res.data.msg);
        }
        // axios.post(CONFIG.SERVER_URL+'leadership/delete/1000').then((res) => {
        dispatch({
            type: UPDATE_ITEM_TESTIMONIAL,
            payload: res.data.data,
            id
        })
    }).catch((error)=> {
        toast.error("Something went wrong");
    })
}
