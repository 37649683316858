import React from 'react'

const newsRoutes = [
   
    {
        path: '/invoice/add',
        component: React.lazy(() => import('./Add')),
    } , 
    {
        path: '/clients/add',
        component: React.lazy(() => import('./users/Add')),
    } ,  
    {
        path: '/clients/',
        component: React.lazy(() => import('./users/Index')),
    } ,          
    {
        path: '/invoice',
        component: React.lazy(() => import('./Index')),
    } 
]

export default newsRoutes
