import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios'
import CONFIG from "../../../config.json";
export const GET_SERVICE_ITEM = 'GET_SERVICE_ITEM'
export const ADD_SERVICE_ITEM = 'ADD_SERVICE_ITEM'
export const DELETE_SERVICE_ITEM = 'DELETE_SERVICE_ITEM'
export const UPDATE_SERVICE_ITEM = 'UPDATE_SERVICE_ITEM'

export const GET_SERVICE_CONTENT = 'GET_SERVICE_CONTENT'
export const ADD_SERVICE_CONTENT = 'ADD_SERVICE_CONTENT'
export const DELETE_SERVICE_CONTENT = 'DELETE_SERVICE_CONTENT'
export const UPDATE_SERVICE_CONTENT = 'UPDATE_SERVICE_CONTENT'

export const getDataItems = () => (dispatch) => {
    axios.get(CONFIG.SERVER_URL + 'service/items').then((res) => {
        dispatch({
            type: GET_SERVICE_ITEM,
            payload: JSON.parse(res.data.data),
        })
    })

}

export const addDataItems = (data) => (dispatch) => {
    const formData = new FormData();

    // Update the formData object 
    console.log('getData', data)
    
    
    formData.append(
        "title",
        data.title
    );
  
    formData.append(
        "description",
        data.description
    );
    if (data.parent) {
        formData.append(
            "parent",
            data.parent
        );
    }
    
    formData.append(
        "status",
        data.status
    );
    formData.append(
        "content",
        data.content
    );

    axios.post(CONFIG.SERVER_URL + 'service/items/add', formData).then((res) => {
        if (res.data.status == true) {
            toast.success(res.data.msg);
        } else {
            toast.error(res.data.msg);
        }
        dispatch({
            type: ADD_SERVICE_ITEM,
            payload: res.data.data,
        })
    }).catch((error) => {
        toast.error("Something went wrong");
    })
}

export const deleteDataItems = (id) => (dispatch) => {

    axios.post(CONFIG.SERVER_URL + 'service/items/delete/' + id).then((res) => {

        if (res.data.status == true) {
            toast.success(res.data.msg);
        } else {
            toast.error(res.data.msg);
        }
        // axios.post(CONFIG.SERVER_URL+'leadership/delete/1000').then((res) => {
        dispatch({
            type: DELETE_SERVICE_ITEM,
            payload: res.data,
            id
        })
    }).catch((error) => {
        toast.error("Something went wrong");
    })
}
export const updateDataItems = (data, id) => (dispatch) => {
    const formData = new FormData();

    // Update the formData object 
    formData.append(
        "title",
        data.title
    );
  
    formData.append(
        "description",
        data.description
    );
    if (data.parent) {
        formData.append(
            "parent",
            data.parent
        );
    }
    
    formData.append(
        "status",
        data.status
    );
    formData.append(
        "content",
        data.content
    );
    axios.post(CONFIG.SERVER_URL + 'service/items/update/' + id, formData).then((res) => {
        if (res.data.status == true) {
            toast.success(res.data.msg);
        } else {
            toast.error(res.data.msg);
        }
        // axios.post(CONFIG.SERVER_URL+'leadership/delete/1000').then((res) => {
        dispatch({
            type: UPDATE_SERVICE_ITEM,
            payload: res.data.data,
            id
        })
    }).catch((error) => {
        toast.error("Something went wrong");
    })
}


export const getDataContent = () => (dispatch) => {
    axios.get(CONFIG.SERVER_URL + 'service/content').then((res) => {
        dispatch({
            type: GET_SERVICE_CONTENT,
            payload: JSON.parse(res.data.data),
        })
    })

}

export const addDataContent = (data) => (dispatch) => {
    const formData = new FormData();

    // Update the formData object 
    console.log('getData', data)
    
    if (data.file) {
        formData.append(
            "file",
            data.file,
            data.file.name,
        );
    }
    formData.append(
        "title",
        data.title
    );
    formData.append(
        "content",
        data.content
    );
    if (data.parent) {
        formData.append(
            "parent",
            data.parent
        );
    }
    
    formData.append(
        "status",
        data.status
    );
    formData.append(
        "ordering",
        data.ordering
    );
    formData.append(
        "alt_tag",
        data.alt_tag
    );

    axios.post(CONFIG.SERVER_URL + 'service/content/add', formData).then((res) => {
        if (res.data.status == true) {
            toast.success(res.data.msg);
        } else {
            toast.error(res.data.msg);
        }
        dispatch({
            type: ADD_SERVICE_CONTENT,
            payload: res.data.data,
        })
    }).catch((error) => {
        toast.error("Something went wrong");
    })
}

export const deleteDataContent = (id) => (dispatch) => {

    axios.post(CONFIG.SERVER_URL + 'service/content/delete/' + id).then((res) => {

        if (res.data.status == true) {
            toast.success(res.data.msg);
        } else {
            toast.error(res.data.msg);
        }
        // axios.post(CONFIG.SERVER_URL+'leadership/delete/1000').then((res) => {
        dispatch({
            type: DELETE_SERVICE_CONTENT,
            payload: res.data,
            id
        })
    }).catch((error) => {
        toast.error("Something went wrong");
    })
}
export const updateDataContent = (data, id) => (dispatch) => {
    const formData = new FormData();

    // Update the formData object 
    if (data.file) {
        formData.append(
            "file",
            data.file,
            data.file.name,
        );
    }
    formData.append(
        "title",
        data.title
    );
    formData.append(
        "content",
        data.content
    );
    if (data.parent) {
        formData.append(
            "parent",
            data.parent
        );
    }
    
    formData.append(
        "status",
        data.status
    );
    formData.append(
        "ordering",
        data.ordering
    );
    formData.append(
        "alt_tag",
        data.alt_tag
    );
    axios.post(CONFIG.SERVER_URL + 'service/content/update/' + id, formData).then((res) => {
        if (res.data.status == true) {
            toast.success(res.data.msg);
        } else {
            toast.error(res.data.msg);
        }
        // axios.post(CONFIG.SERVER_URL+'leadership/delete/1000').then((res) => {
        dispatch({
            type: UPDATE_SERVICE_CONTENT,
            payload: res.data.data,
            id
        })
    }).catch((error) => {
        toast.error("Something went wrong");
    })
}

