import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios'
import CONFIG from "../../../config.json";
export const GET_LIST_NEWS = 'GET_LIST_NEWS'
export const ADD_ITEM_NEWS = 'ADD_ITEM_NEWS'
export const DELETE_ITEM_NEWS = 'DELETE_ITEM_NEWS'
export const UPDATE_ITEM_NEWS = 'UPDATE_ITEM_NEWS'
export const getData = () => (dispatch) => {
    axios.get(CONFIG.SERVER_URL+'news').then((res) => {
        dispatch({
            type: GET_LIST_NEWS,
            payload: JSON.parse(res.data.data),
        })
    })

}

export const addData = (data) => (dispatch) => {
    const formData = new FormData();

    // Update the formData object 
    console.log('getData', data)
    
    if(data.file){
        formData.append(
            "file",
            data.file,
            data.file.name,
        );
    }
    formData.append(
        "title",
        data.title
    );
    formData.append(
        "status",
        data.status
    );
    formData.append(
        "date",
        data.date
    );
    formData.append(
        "details",
        data.details
    );
    formData.append(
        "archieved",
        data.archieved
    );
    formData.append(
        "alt_tag",
        data.alt_tag
    );
    
    axios.post(CONFIG.SERVER_URL+'news/add', formData).then((res) => {
        if(res.data.status == true){
            toast.success(res.data.msg);
        }else{
            toast.error(res.data.msg);
        }
        dispatch({
            type: ADD_ITEM_NEWS,
            payload: res.data.data,
        })
    }).catch((error)=> {
        toast.error("Something went wrong");
    })
}

export const deleteData = (id) => (dispatch) => {
    
    axios.post(CONFIG.SERVER_URL+'news/delete/'+id).then((res) => {

        if(res.data.status == true){
            toast.success(res.data.msg);
        }else{
            toast.error(res.data.msg);
        }
        // axios.post(CONFIG.SERVER_URL+'leadership/delete/1000').then((res) => {
        dispatch({
            type: DELETE_ITEM_NEWS,
            payload: res.data,
            id
        })
    }).catch((error)=> {
        toast.error("Something went wrong");
    })
}
export const updateData = (data, id) => (dispatch) => {
    console.log("updateData",data)
    const formData = new FormData();

    // Update the formData object 
    if(data.file){
        formData.append(
            "file",
            data.file,
            data.file.name,
        );
    }
    formData.append(
        "title",
        data.title
    );
    formData.append(
        "status",
        data.status
    );
    formData.append(
        "details",
        data.details
    );
    formData.append(
        "date",
        data.date
    );
    formData.append(
        "archieved",
        data.archieved
    );
    formData.append(
        "alt_tag",
        data.alt_tag
    );
    axios.post(CONFIG.SERVER_URL+'news/update/'+id, formData).then((res) => {
        if(res.data.status == true){
            toast.success(res.data.msg);
        }else{
            toast.error(res.data.msg);
        }
        // axios.post(CONFIG.SERVER_URL+'leadership/delete/1000').then((res) => {
        dispatch({
            type: UPDATE_ITEM_NEWS,
            payload: res.data.data,
            id
        })
    }).catch((error)=> {
        toast.error("Something went wrong");
    })
}
