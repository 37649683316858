import {
    GET_LIST_NEWS,
    ADD_ITEM_NEWS,
    DELETE_ITEM_NEWS,
    UPDATE_ITEM_NEWS
} from '../actions/NewsActions'

const initialState = {
    list: []  
}

const NewsReducer = function (state = initialState, action) {    
    console.log("action", action);
    switch (action.type) {
        case GET_LIST_NEWS: {            
            return {
                ...state,
                list: [...action.payload],
            }
        }   
        case ADD_ITEM_NEWS: {                  
            state.list.push(action.payload);            
            return {
                ...state                
            }            
        }  
        case DELETE_ITEM_NEWS: {
            const index = state.list.findIndex((item) => {
                return item.id == action.id
            })
            if(index >= 0){
                state.list.splice(index, 1);
            }  
            return {
                ...state                
            }            
        }       
        case UPDATE_ITEM_NEWS: {
            const index = state.list.findIndex((item) => {
                return item.id == action.id
            })
            console.log("indexe", index)
            console.log("before splice", state.list)
            if(index >= 0){
                state.list.splice(index, 1);
            }  
            console.log("after splice", state.list)
            state.list.push(action.payload); 
            console.log("after push", state.list)
            return {
                ...state                
            }            
        }        
        default: {
            return {
                ...state,
            }
        }
    }
}

export default NewsReducer
