import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios'
import CONFIG from "../../../config.json";

export const GET_GALLERY_LIST = 'GET_GALLERY_LIST'
export const ADD_GALLERY = 'ADD_GALLERY'
export const DELETE_GALLERY = 'DELETE_GALLERY'




export const getGallery = () => (dispatch) => {
    axios.get(CONFIG.SERVER_URL+'gallery').then((res) => {
        if(res.data.status == true){
            // toast.success(res.data.msg);
        }else{
            toast.error(res.data.msg);
        }
        dispatch({
            type: GET_GALLERY_LIST,
            payload: JSON.parse(res.data.data),
        })
    }).catch((error)=> {
        toast.error("Something went wrong");
    })

}

export const addGallery = (data) => (dispatch) => {
    const formData = new FormData();

    // Update the formData object 
    console.log('getData', data)
    

    if(data.files.length){
        data.files.forEach(element => {
            formData.append(
                "file[]",
                element,
                element.name
            );
        });
    }
    formData.append(
        "title",
        ""
    );
    formData.append(
        "type",
        "GENERAL"
    );
    
    axios.post(CONFIG.SERVER_URL+'gallery/add', formData).then((res) => {
        if(res.data.status == true){
            toast.success(res.data.msg);
        }else{
            toast.error(res.data.msg);
        }
        dispatch({
            type: ADD_GALLERY,
            payload: res.data.data,
        })
    }).catch((error)=> {
        toast.error("Something went wrong");
    })
}

export const deleteGallery = (id) => (dispatch) => {
    
    axios.post(CONFIG.SERVER_URL+'gallery/delete/'+id).then((res) => {

        if(res.data.status == true){
            toast.success(res.data.msg);
        }else{
            toast.error(res.data.msg);
        }
        // axios.post(CONFIG.SERVER_URL+'leadership/delete/1000').then((res) => {
        dispatch({
            type: DELETE_GALLERY,
            payload: res.data,
            id
        })
    }).catch((error)=> {
        toast.error("Something went wrong");
    })
}


