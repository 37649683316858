import React from 'react'
import { Redirect } from 'react-router-dom'


import dashboardRoutes from './views/dashboard/DashboardRoutes'
import utilitiesRoutes from './views/utilities/UtilitiesRoutes'

import materialRoutes from './views/material-kit/MaterialRoutes'
import leadershipRoutes from './views/leadership/LeadershipRoutes'
// import chartsRoute from './views/charts/ChartsRoute'
// import dragAndDropRoute from './views/Drag&Drop/DragAndDropRoute'

// import formsRoutes from './views/forms/FormsRoutes'
// import mapRoutes from './views/map/MapRoutes'
import servicesRoutes from './views/services/ServicesRoutes'
import milestonesRoutes from './views/milestones/Routes'
import testimonialsRoutes from './views/testimonials/Routes'
import newsRoutes from './views/news/Routes'
import CareerRoutes from './views/career/CareerRoutes'
import MediaRoutes from './views/media/MediaRoutes'
import BlogRoutes from './views/blog/blogRoutes'
import UserRoutes from './views/users/userRoutes'
import InvoiceRoutes from './views/invoice/Routes'
import ConversionRoutes from './views/conversion/Routes'
import EnquiryRoutes from './views/enquiry/Routes'


const redirectRoute = [
    {
        path: '/',
        exact: true,
        component: () => <Redirect to="/dashboard/default" />,
    },
]

const errorRoute = [
    {
        component: () => <Redirect to="/session/404" />,
    },
]

const routes = [
    ...dashboardRoutes,
    ...leadershipRoutes,
    ...milestonesRoutes,
    ...testimonialsRoutes,
    ...newsRoutes,
    ...materialRoutes,
    ...utilitiesRoutes,
    ...servicesRoutes,
    ...CareerRoutes,
    ...MediaRoutes,
    ...ConversionRoutes,
    ...EnquiryRoutes,
     ...BlogRoutes,
    ...UserRoutes,
    ...InvoiceRoutes,
    ...redirectRoute,
    ...errorRoute,
]

export default routes
