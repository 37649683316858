import {
    GET_LIST_USERS,
    ADD_ITEM_USERS,
    DELETE_ITEM_USERS,
    UPDATE_ITEM_USERS
} from '../actions/UsersActions'

const initialState = {
    UsersList: []  
}

const UsersReducer = function (state = initialState, action) {    
    console.log("action", action);
    switch (action.type) {
        case GET_LIST_USERS: {            
            return {
                ...state,
                UsersList: [...action.payload],
            }
        }   
        case ADD_ITEM_USERS: {                  
            state.UsersList.push(action.payload);            
            return {
                ...state                
            }            
        }  
        case DELETE_ITEM_USERS: {
            const index = state.UsersList.findIndex((item) => {
                return item.id == action.id
            })
            if(index >= 0){
                state.UsersList.splice(index, 1);
            }  
            return {
                ...state                
            }            
        }       
        case UPDATE_ITEM_USERS: {
            const index = state.UsersList.findIndex((item) => {
                return item.id == action.id
            })
            console.log("indexe", index)
            console.log("before splice", state.UsersList)
            if(index >= 0){
                state.UsersList.splice(index, 1);
            }  
            console.log("after splice", state.UsersList)
            state.UsersList.push(action.payload); 
            console.log("after push", state.UsersList)
            return {
                ...state                
            }            
        }        
        default: {
            return {
                ...state,
            }
        }
    }
}

export default UsersReducer
