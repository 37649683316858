import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios'
import CONFIG from "../../../config.json";
export const GET_INVOICE_LIST = 'GET_INVOICE_LIST'
export const ADD_INVOICE = 'ADD_INVOICE'
export const DELETE_INVOICE = 'DELETE_INVOICE'
export const UPDATE_INVOICE = 'UPDATE_INVOICE'
export const UPDATE_CONVERSION_RATE = "UPDATE_CONVERSION_RATE"
export const GET_CONVERSION = "GET_CONVERSION"




export const getInvoice = () => (dispatch) => {
    axios.get(CONFIG.SERVER_URL+'invoice').then((res) => {
        if(res.data.status == true){
            toast.success(res.data.msg);
        }else{
            toast.error(res.data.msg);
        }
        dispatch({
            type: GET_INVOICE_LIST,
            payload: JSON.parse(res.data.data),
        })
    }).catch((error)=> {
        toast.error("Something went wrong");
    })

}

export const addData = (data) => (dispatch) => {
    const formData = new FormData();

    // Update the formData object 
    console.log('getData', data)
    // return false;
    formData.append(
        "amount",
        data.amount
    );
    formData.append(
        "billing_date",
        data.billing_date
    );
    formData.append(
        "client",
        data.client
    );
    formData.append(
        "currency",
        data.currency
    );
    formData.append(
        "invoiceNumber",
        data.invoiceNumber
    );
    formData.append(
        "invoiceStatus",
        data.invoiceStatus
    );
    formData.append(
        "service",
        data.service
    );
    formData.append(
        "status",
        data.status
    );
    axios.post(CONFIG.SERVER_URL+'invoice/add', formData).then((res) => {
        if(res.data.status == true){
            toast.success(res.data.msg);
        }else{
            toast.error(res.data.msg);
        }
        dispatch({
            type: ADD_INVOICE,
            payload: res.data.data,
        })
    }).catch((error)=> {
        toast.error("Something went wrong");
    })
}

export const deleteData = (id) => (dispatch) => {
    
    axios.post(CONFIG.SERVER_URL+'invoice/delete/'+id).then((res) => {

        if(res.data.status == true){
            toast.success(res.data.msg);
        }else{
            toast.error(res.data.msg);
        }
        // axios.post(CONFIG.SERVER_URL+'leadership/delete/1000').then((res) => {
        dispatch({
            type: DELETE_INVOICE,
            payload: res.data,
            id
        })
    }).catch((error)=> {
        toast.error("Something went wrong");
    })
}
export const updateData = (data, id) => (dispatch) => {
    const formData = new FormData();

    // Update the formData object 
    console.log('getData', data)
    formData.append(
        "amount",
        data.amount
    );
    formData.append(
        "billing_date",
        data.billing_date
    );
    formData.append(
        "client",
        data.client
    );
    formData.append(
        "currency",
        data.currency
    );
    formData.append(
        "invoiceNumber",
        data.invoiceNumber
    );
    formData.append(
        "invoiceStatus",
        data.invoiceStatus
    );
    formData.append(
        "service",
        data.service
    );
    formData.append(
        "status",
        data.status
    );
    axios.post(CONFIG.SERVER_URL+'invoice/update/'+id, formData).then((res) => {
        if(res.data.status == true){
            toast.success(res.data.msg);
        }else{
            toast.error(res.data.msg);
        }
        // axios.post(CONFIG.SERVER_URL+'leadership/delete/1000').then((res) => {
        dispatch({
            type: UPDATE_INVOICE,
            payload: res.data.data,
            id
        })
    }).catch((error)=> {
        toast.error("Something went wrong");
    })
}
export const updateConversionData = (data) => (dispatch) => {
    const formData = new FormData();

    // Update the formData object 
    console.log('getData', data)
    formData.append(
        "rate",
        data.rate
    );
    
    axios.post(CONFIG.SERVER_URL+'invoice/conversionUpdate/', formData).then((res) => {
        if(res.data.status == true){
            toast.success(res.data.msg);
        }else{
            toast.error(res.data.msg);
        }
        // axios.post(CONFIG.SERVER_URL+'leadership/delete/1000').then((res) => {
        dispatch({
            type: UPDATE_CONVERSION_RATE,
            payload: res.data.data            
        })
    }).catch((error)=> {
        toast.error("Something went wrong");
    })
}
export const getConversionData = (data) => (dispatch) => {

    axios.get(CONFIG.SERVER_URL+'invoice/conversion').then((res) => {
        if(res.data.status == true){
            toast.success(res.data.msg);
        }else{
            toast.error(res.data.msg);
        }
        dispatch({
            type: GET_CONVERSION,
            payload: JSON.parse(res.data.data),
        })
    }).catch((error)=> {
        console.log(error);
        toast.error("121212Something went wrong");
    })    
}



