import { combineReducers } from 'redux'
import ScrumBoardReducer from './ScrumBoardReducer'
import NotificationReducer from './NotificationReducer'
import EcommerceReducer from './EcommerceReducer'
import LeadershipReducer from './LeadershipReducer'
import ServiceReducer from './ServiceReducer'
import NavigationReducer from './NavigationReducer'
import TestimonialsReducer from './TestimonialsReducer'
import MilestoneReducer from './MilestoneReducer'
import NewsReducer from './NewsReducer'
import CareersReducer from './CareersReducer'
import ServiceItemReducer from './ServiceItemReducer'
import GalleryReducer from './GalleryReducer'
import BlogReducer from './BlogReducer'
import UsersReducer from './UsersReducer'
import InvoiceReducer from './InvoiceReducer'
import EnquiryReducer from './EnquiryReducer'

const RootReducer = combineReducers({
    notifications: NotificationReducer,
    navigations: NavigationReducer,
    scrumboard: ScrumBoardReducer,
    ecommerce: EcommerceReducer,
    leadership: LeadershipReducer,
    services: ServiceReducer,
    milestones:MilestoneReducer,
    testimonials:TestimonialsReducer,
    news:NewsReducer,
    career:CareersReducer,
    serviceItems:ServiceItemReducer,
    gallery : GalleryReducer,
    blog : BlogReducer,
    users : UsersReducer,
    invoice : InvoiceReducer,
    enquiry : EnquiryReducer,

})

export default RootReducer
