import React from 'react';
const servicesRoutes =[
    
    {
        path: '/blog/category/add',
        component: React.lazy(() => import('./category/Add')),
    },
    {
        path: '/blog/post/add',
        component: React.lazy(() => import('./post/Add')),
    },
    {
        path: '/blog/post',
        component: React.lazy(() => import('./post/Index')),
    },
  
    {
        path: '/blog/category/',
        component: React.lazy(() => import('./category/Index')),
    },
]

export default servicesRoutes