import React from 'react'

const leadershipRoutes = [
   
    {
        path: '/leadership/add',
        component: React.lazy(() => import('./Add')),
    } ,  
    {
        path: '/leadership/update/:id',
        component: React.lazy(() => import('./Update')),
    } ,   
    
    {
        path: '/leadership',
        component: React.lazy(() => import('./Index')),
    } 
]

export default leadershipRoutes
