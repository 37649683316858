import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios'
import CONFIG from "../../../config.json";
export const GET_CAREERS_LIST = 'GET_CAREERS_LIST'
export const ADD_CAREERS = 'ADD_CAREERS'
export const DELETE_CAREERS = 'DELETE_CAREERS'
export const UPDATE_CAREERS = 'UPDATE_CAREERS'

export const GET_CAREERS_GALLERY_LIST = 'GET_CAREERS_GALLERY_LIST'
export const ADD_CAREERS_GALLERY = 'ADD_CAREERS_GALLERY'
export const DELETE_CAREERS_GALLERY = 'DELETE_CAREERS_GALLERY'

export const GET_JOB_APPLICATIONS = 'GET_JOB_APPLICATIONS'


export const getData = () => (dispatch) => {
    axios.get(CONFIG.SERVER_URL+'career').then((res) => {
        if(res.data.status == true){
            toast.success(res.data.msg);
        }else{
            toast.error(res.data.msg);
        }
        dispatch({
            type: GET_CAREERS_LIST,
            payload: JSON.parse(res.data.data),
        })
    }).catch((error)=> {
        toast.error("Something went wrong");
    })

}

export const addData = (data) => (dispatch) => {
    const formData = new FormData();

    // Update the formData object 
    console.log('getData', data)
    
    formData.append(
        "title",
        data.title
    );
    formData.append(
        "location",
        data.location
    );
    formData.append(
        "exp",
        data.exp
    );
    formData.append(
        "responsibility",
        data.responsibility
    );
    formData.append(
        "skills",
        data.skills
    );
    formData.append(
        "status",
        data.status
    );
    axios.post(CONFIG.SERVER_URL+'career/add', formData).then((res) => {
        if(res.data.status == true){
            toast.success(res.data.msg);
        }else{
            toast.error(res.data.msg);
        }
        dispatch({
            type: ADD_CAREERS,
            payload: res.data.data,
        })
    }).catch((error)=> {
        toast.error("Something went wrong");
    })
}

export const deleteData = (id) => (dispatch) => {
    
    axios.post(CONFIG.SERVER_URL+'career/delete/'+id).then((res) => {

        if(res.data.status == true){
            toast.success(res.data.msg);
        }else{
            toast.error(res.data.msg);
        }
        // axios.post(CONFIG.SERVER_URL+'leadership/delete/1000').then((res) => {
        dispatch({
            type: DELETE_CAREERS,
            payload: res.data,
            id
        })
    }).catch((error)=> {
        toast.error("Something went wrong");
    })
}
export const updateData = (data, id) => (dispatch) => {
    const formData = new FormData();

    // Update the formData object 
    console.log('getData', data)
    formData.append(
        "title",
        data.title
    );
    formData.append(
        "location",
        data.location
    );
    formData.append(
        "exp",
        data.exp
    );
    formData.append(
        "responsibility",
        data.responsibility
    );
    formData.append(
        "skills",
        data.skills
    );
    formData.append(
        "status",
        data.status
    );
    axios.post(CONFIG.SERVER_URL+'career/update/'+id, formData).then((res) => {
        if(res.data.status == true){
            toast.success(res.data.msg);
        }else{
            toast.error(res.data.msg);
        }
        // axios.post(CONFIG.SERVER_URL+'leadership/delete/1000').then((res) => {
        dispatch({
            type: UPDATE_CAREERS,
            payload: res.data.data,
            id
        })
    }).catch((error)=> {
        toast.error("Something went wrong");
    })
}

export const getGallery = () => (dispatch) => {
    axios.get(CONFIG.SERVER_URL+'gallery').then((res) => {
        if(res.data.status == true){
            // toast.success(res.data.msg);
        }else{
            toast.error(res.data.msg);
        }
        dispatch({
            type: GET_CAREERS_GALLERY_LIST,
            payload: JSON.parse(res.data.data),
        })
    }).catch((error)=> {
        toast.error("Something went wrong");
    })

}

export const addGallery = (data) => (dispatch) => {
    const formData = new FormData();

    // Update the formData object 
    console.log('getData', data)
    

    if(data.files.length){
        data.files.forEach(element => {
            formData.append(
                "file[]",
                element,
                element.name
            );
        });
    }
    formData.append(
        "title",
        ""
    );
    formData.append(
        "type",
        "CAREER"
    );
    
    axios.post(CONFIG.SERVER_URL+'gallery/add', formData).then((res) => {
        if(res.data.status == true){
            toast.success(res.data.msg);
        }else{
            toast.error(res.data.msg);
        }
        dispatch({
            type: ADD_CAREERS_GALLERY,
            payload: res.data.data,
        })
    }).catch((error)=> {
        toast.error("Something went wrong");
    })
}

export const deleteGallery = (id) => (dispatch) => {
    
    axios.post(CONFIG.SERVER_URL+'gallery/delete/'+id).then((res) => {

        if(res.data.status == true){
            toast.success(res.data.msg);
        }else{
            toast.error(res.data.msg);
        }
        // axios.post(CONFIG.SERVER_URL+'leadership/delete/1000').then((res) => {
        dispatch({
            type: DELETE_CAREERS_GALLERY,
            payload: res.data,
            id
        })
    }).catch((error)=> {
        toast.error("Something went wrong");
    })
}


export const getJobApplications = () => (dispatch) => {
    axios.get(CONFIG.SERVER_URL+'career/applications').then((res) => {
        if(res.data.status == true){
            toast.success(res.data.msg);
        }else{
            toast.error(res.data.msg);
        }
        dispatch({
            type: GET_JOB_APPLICATIONS,
            payload: JSON.parse(res.data.data),
        })
    }).catch((error)=> {
        toast.error("Something went wrong");
    })

}