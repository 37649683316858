import React, { createContext, useEffect, useReducer } from 'react'

import jwtDecode from 'jwt-decode'
import axios from 'axios'
import { MatxLoading } from 'app/components'
import CONFIG from "../../config.json";

const initialState = {
    isAuthenticated: false,
    isInitialised: false,
    user: null,
}

const isValidToken = (accessToken) => {
    if (!accessToken) {
        return false
    }

    // const decodedToken = jwtDecode(accessToken)
    const decodedToken = JSON.parse(atob(accessToken));
    const currentTime = Date.now() / 1000
    console.log("currentTime", decodedToken)
    console.log(decodedToken.exp > currentTime)
    return decodedToken.exp > currentTime
}

const setSession = (accessToken) => {
    if (accessToken) {
        localStorage.setItem('accessToken', accessToken)
        localStorage.setItem('tokenTime', new Date().getTime() + (60 *60 *2));
        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
        axios.interceptors.response.use(function (response) {
            
            console.log("response", response)
            if(response.data.stausCode == 401){
                localStorage.removeItem('accessToken')
                axios.defaults.headers.common.Authorization = `Bearer xyz`
                window.location.reload()
            }
            // Do something with response data
             return response;
          }, function (error) {            
              console.log("error-->", error)
            localStorage.removeItem('accessToken')
            axios.defaults.headers.common.Authorization = `Bearer xyz`
            window.location.reload()
            // Do something with response error
            return Promise.reject(error);
          });
        
    } else {
        localStorage.removeItem('accessToken')
        localStorage.removeItem('tokenTime')
        delete axios.defaults.headers.common.Authorization

        
    }
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'INIT': {
            const { isAuthenticated, user } = action.payload

            return {
                ...state,
                isAuthenticated,
                isInitialised: true,
                user,
            }
        }
        case 'LOGIN': {
            const { user } = action.payload

            return {
                ...state,
                isAuthenticated: true,
                user,
            }
        }
        case 'LOGOUT': {
            return {
                ...state,
                isAuthenticated: false,
                user: null,
            }
        }
        case 'REGISTER': {
            const { user } = action.payload

            return {
                ...state,
                isAuthenticated: true,
                user,
            }
        }
        default: {
            return { ...state }
        }
    }
}

const AuthContext = createContext({
    ...initialState,
    method: 'JWT',
    login: () => Promise.resolve(),
    forgot: () => Promise.resolve(),
    logout: () => {},
    register: () => Promise.resolve(),
})

export const AuthProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState)

    const login = async (email, password) => {
        console.log("email", email);
        console.log("password", password);
        const response = await axios.post(CONFIG.SERVER_URL+'auth/login', {
            username : email,
            password : password,
        })

        console.log(response.data);
        const { accessToken } = response.data
        if(response.data.status){
            console.log("response.data", response.data)
            const user = JSON.parse(atob(accessToken));
            console.log(user);
            setSession(accessToken)
            dispatch({
                type: 'LOGIN',
                payload: {
                    user,
                },
            })
        }else{
            throw response.data.msg;
        }


        
    }
    const forgot = async (email) => {
        console.log("email", email);        
        const response = await axios.post(CONFIG.SERVER_URL+'auth/forgot-password', {
            email
        })
        return response.data;
    }

    const register = async (email, username, password) => {
        const response = await axios.post(CONFIG.SERVER_URL+'/auth/login', {
            email,
            username,
            password,
        })

        const { accessToken, user } = response.data

        setSession(accessToken)

        dispatch({
            type: 'REGISTER',
            payload: {
                user,
            },
        })
    }

    const logout = async () => {
        const accessToken = window.localStorage.getItem('accessToken')
        
        const response = await axios.post(CONFIG.SERVER_URL+'auth/logout', {
            token : accessToken                        
        })
       
        setSession(null)
        dispatch({ type: 'LOGOUT' })
    }

    useEffect(() => {
        ;(async () => {
            try {
                const accessToken = window.localStorage.getItem('accessToken')

                if (accessToken && isValidToken(accessToken)) {
                    setSession(accessToken)
                    // const response = await axios.post(CONFIG.SERVER_URL+'auth/checkToken', {
                    //     token : accessToken                        
                    // })
                    const user  = JSON.parse(atob(accessToken));

                    dispatch({
                        type: 'INIT',
                        payload: {
                            isAuthenticated: true,
                            user,
                        },
                    })
                } else {
                    dispatch({
                        type: 'INIT',
                        payload: {
                            isAuthenticated: false,
                            user: null,
                        },
                    })
                }
            } catch (err) {
                console.error(err)
                dispatch({
                    type: 'INIT',
                    payload: {
                        isAuthenticated: false,
                        user: null,
                    },
                })
            }
        })()
    }, [])

    if (!state.isInitialised) {
        return <MatxLoading />
    }

    return (
        <AuthContext.Provider
            value={{
                ...state,
                method: 'JWT',
                login,
                logout,
                register,
                forgot
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext
