import {
    GET_SERVICE_ITEM,
    ADD_SERVICE_ITEM,
    DELETE_SERVICE_ITEM,
    UPDATE_SERVICE_ITEM,
    GET_SERVICE_CONTENT,
    ADD_SERVICE_CONTENT,
    DELETE_SERVICE_CONTENT,
    UPDATE_SERVICE_CONTENT
    
} from '../actions/ServiceItemAction'

const initialState = {
    ServiceItemsList: [],
    ServiceContentList: []

}

const ServiceItemsReducer = function (state = initialState, action) {    
    console.log("action", action);
    switch (action.type) {
        case GET_SERVICE_ITEM: {            
            return {
                ...state,
                ServiceItemsList: [...action.payload],
            }
        }   
        case ADD_SERVICE_ITEM: {     
            console.log("action.payload", action.payload)             
            console.log("state.ServiceItemsList", state.ServiceItemsList)             
            state.ServiceItemsList.push(action.payload);            
            console.log("state.ServiceItemsList after", state.ServiceItemsList)             
            return {
                ...state                
            }            
        }  
        case DELETE_SERVICE_ITEM: {
            const index = state.ServiceItemsList.findIndex((item) => {
                return item.id == action.id
            })
            if(index >= 0){
                state.ServiceItemsList.splice(index, 1);
            }  
            return {
                ...state                
            }            
        }       
        case UPDATE_SERVICE_ITEM: {
            const index = state.ServiceItemsList.findIndex((item) => {
                return item.id == action.id
            })
            console.log("indexe", index)
            console.log("before splice", state.ServiceItemsList)
            if(index >= 0){
                state.ServiceItemsList.splice(index, 1);
            }  
            console.log("after splice", state.ServiceItemsList)
            state.ServiceItemsList.push(action.payload); 
            console.log("after push", state.ServiceItemsList)
            return {
                ...state                
            }            
        }  
        
        case GET_SERVICE_CONTENT: {            
            return {
                ...state,
                ServiceContentList: [...action.payload],
            }
        }   
        case ADD_SERVICE_CONTENT: {     
            
            state.ServiceContentList.push(action.payload);            
            
            state.ServiceContentList.sort((a,b) => {                
                return a.ordering - b.ordering
            })             
            return {
                ...state                
            }            
        }  
        case DELETE_SERVICE_CONTENT: {
            const index = state.ServiceContentList.findIndex((item) => {
                return item.id == action.id
            })
            if(index >= 0){
                state.ServiceContentList.splice(index, 1);
            }  
            return {
                ...state                
            }            
        }       
        case UPDATE_SERVICE_CONTENT: {
            const index = state.ServiceContentList.findIndex((item) => {
                return item.id == action.id
            })
            
            if(index >= 0){
                state.ServiceContentList.splice(index, 1);
            }  
            
            state.ServiceContentList.push(action.payload); 
            
            state.ServiceContentList.sort((a,b) => {                
                return a.ordering - b.ordering
            }) 
            return {
                ...state                
            }            
        }  
        default: {
            return {
                ...state,
            }
        }
    }
}

export default ServiceItemsReducer
