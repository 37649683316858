import React from 'react'

const CareerRoutes = [
   
    {
        path: '/media/add',
        component: React.lazy(() => import('./Gallery')),
    } ,  
    {
        path: '/media',
        component: React.lazy(() => import('./Index')),
    } ]

export default CareerRoutes
