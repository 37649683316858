import {
    GET_LEADERSHIP_LIST,
    ADD_LEADERSHIP,
    DELETE_LEADERSHIP,
    UPDATE_LEADERSHIP
} from '../actions/LeadershipAction'

const initialState = {
    leadershipList: []  
}

const LeadershipReducer = function (state = initialState, action) {    
    console.log("action", action);
    switch (action.type) {
        case GET_LEADERSHIP_LIST: {            
            return {
                ...state,
                leadershipList: [...action.payload],
            }
        }   
        case ADD_LEADERSHIP: {                  
            state.leadershipList.push(action.payload);
            state.leadershipList.sort((a,b) => {                
                return a.ordering - b.ordering
            })
            
            return {
                ...state                
            }            
        }  
        case DELETE_LEADERSHIP: {
            const index = state.leadershipList.findIndex((item) => {
                return item.id == action.id
            })
            if(index >= 0){
                state.leadershipList.splice(index, 1);
            }  
            return {
                ...state                
            }            
        }       
        case UPDATE_LEADERSHIP: {
            const index = state.leadershipList.findIndex((item) => {
                return item.id == action.id
            })
           
            if(index >= 0){
                state.leadershipList.splice(index, 1);
            }  
           
            state.leadershipList.push(action.payload); 


            console.log("state.leadershipList before", state.leadershipList)
            state.leadershipList.sort((a,b) => {                
                return a.ordering - b.ordering
            })
            console.log("state.leadershipList after", state.leadershipList)


            console.log("after push", state.leadershipList)
            return {
                ...state                
            }            
        }        
        default: {
            return {
                ...state,
            }
        }
    }
}

export default LeadershipReducer
