import {
    GET_LIST_SERVICE,
    ADD_ITEM_SERVICE,
    DELETE_ITEM_SERVICE,
    UPDATE_ITEM_SERVICE
} from '../actions/ServiceAction'

const initialState = {
    ServiceList: []  
}

const MilestonesReducer = function (state = initialState, action) {    
    console.log("action", action);
    switch (action.type) {
        case GET_LIST_SERVICE: {            
            return {
                ...state,
                ServiceList: [...action.payload],
            }
        }   
        case ADD_ITEM_SERVICE: {     
            
            state.ServiceList.push(action.payload);                        
            state.ServiceList.sort((a,b) => {                
                return a.ordering - b.ordering
            })         
            return {
                ...state                
            }            
        }  
        case DELETE_ITEM_SERVICE: {
            const index = state.ServiceList.findIndex((item) => {
                return item.id == action.id
            })
            if(index >= 0){
                state.ServiceList.splice(index, 1);
            }  
            return {
                ...state                
            }            
        }       
        case UPDATE_ITEM_SERVICE: {
            const index = state.ServiceList.findIndex((item) => {
                return item.id == action.id
            })            
            if(index >= 0){
                state.ServiceList.splice(index, 1);
            } 
            state.ServiceList.push(action.payload); 
            
            state.ServiceList.sort((a,b) => {                
                return a.ordering - b.ordering
            })         
            return {
                ...state                
            }            
        }        
        default: {
            return {
                ...state,
            }
        }
    }
}

export default MilestonesReducer
