import React from 'react';
const userRoutes =[
    
    {
        path: '/users/add',
        component: React.lazy(() => import('./Add')),
    },
    {
        path: '/users/changepassword',
        component: React.lazy(() => import('./changePasswordTemplet')),
    },
   
    {
        path: '/users',
        component: React.lazy(() => import('./Index')),
    },
  
]

export default userRoutes