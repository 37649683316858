import React from 'react'

const newsRoutes = [
   
    {
        path: '/news/add',
        component: React.lazy(() => import('./Add')),
    } ,  
    {
        path: '/news/update/:id',
        component: React.lazy(() => import('./Update')),
    } ,   
    
    {
        path: '/news',
        component: React.lazy(() => import('./Index')),
    } 
]

export default newsRoutes
