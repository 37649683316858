import {
    GET_LIST_ENQ   
} from '../actions/EnquiryActions'

const initialState = {
    list: []  
}

const EnquiryReducer = function (state = initialState, action) {    
    console.log("action", action);
    switch (action.type) {
        case GET_LIST_ENQ: {            
            return {
                ...state,
                list: [...action.payload],
            }
        }   
             
        default: {
            return {
                ...state,
            }
        }
    }
}

export default EnquiryReducer
