import React from 'react'

const newsRoutes = [
   
      
    
    {
        path: '/conversion',
        component: React.lazy(() => import('./Index')),
    } 
]

export default newsRoutes
