import React from 'react'

const milestonesRoutes = [
   
    {
        path: '/milestones/add',
        component: React.lazy(() => import('./Add')),
    } ,  
    {
        path: '/milestones/update/:id',
        component: React.lazy(() => import('./Update')),
    } ,   
    
    {
        path: '/milestones',
        component: React.lazy(() => import('./Index')),
    } 
]

export default milestonesRoutes
