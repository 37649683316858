import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios'
import CONFIG from "../../../config.json";
export const GET_LIST_USERS = 'GET_LIST_USERS'
export const ADD_ITEM_USERS = 'ADD_ITEM_USERS'
export const DELETE_ITEM_USERS = 'DELETE_ITEM_USERS'
export const UPDATE_ITEM_USERS = 'UPDATE_ITEM_USERS'
export const getData = () => (dispatch) => {
    axios.get(CONFIG.SERVER_URL+'users').then((res) => {
        dispatch({
            type: GET_LIST_USERS,
            payload: JSON.parse(res.data.data),
        })
    })

}

export const addData = (data) => (dispatch) => {
    const formData = new FormData();

    // Update the formData object 
    console.log('getData', data)
    
   
    formData.append(
        "name",
        data.name
    );
    formData.append(
        "username",
        data.username
    );
    formData.append(
        "email",
        data.email
    );

    formData.append(
        "phone",
        data.phone
    );
    formData.append(
        "password",
        data.password
    );
    formData.append(
        "status",
        data.status
    );
    formData.append(
        "role",
        data.role
    );
    formData.append(
        "company",
        data.company
    );
    formData.append(
        "address",
        data.address
    );
    formData.append(
        "city",
        data.city
    );
    formData.append(
        "pincode",
        data.pincode
    );
    formData.append(
        "addressstate",
        data.addressstate
    );
    formData.append(
        "country",
        data.country
    );

    
    
    axios.post(CONFIG.SERVER_URL+'users/add', formData).then((res) => {
        if(res.data.status == true){
            toast.success(res.data.msg);
        }else{
            toast.error(res.data.msg);
        }
        dispatch({
            type: ADD_ITEM_USERS,
            payload: res.data.data,
        })
    }).catch((error)=> {
        toast.error("Something went wrong");
    })
}

export const deleteData = (id) => (dispatch) => {
    
    axios.post(CONFIG.SERVER_URL+'users/delete/'+id).then((res) => {

        if(res.data.status == true){
            toast.success(res.data.msg);
        }else{
            toast.error(res.data.msg);
        }
        // axios.post(CONFIG.SERVER_URL+'leadership/delete/1000').then((res) => {
        dispatch({
            type: DELETE_ITEM_USERS,
            payload: res.data,
            id
        })
    }).catch((error)=> {
        toast.error("Something went wrong");
    })
}
export const updateData = (data, id) => (dispatch) => {
    const formData = new FormData();
    // Update the formData object 
    formData.append(
        "name",
        data.name
    );
    formData.append(
        "username",
        data.username
    );
    formData.append(
        "email",
        data.email
    );

    formData.append(
        "phone",
        data.phone
    );
    formData.append(
        "password",
        data.password
    );
    formData.append(
        "status",
        data.status
    );
    formData.append(
        "role",
        data.role
    );
    formData.append(
        "company",
        data.company
    );
    formData.append(
        "address",
        data.address
    );
    formData.append(
        "city",
        data.city
    );
    formData.append(
        "pincode",
        data.pincode
    );
    formData.append(
        "addressstate",
        data.addressstate
    );
    formData.append(
        "country",
        data.country
    );
    axios.post(CONFIG.SERVER_URL+'users/update/'+id, formData).then((res) => {
        if(res.data.status == true){
            toast.success(res.data.msg);
        }else{
            toast.error(res.data.msg);
        }
        // axios.post(CONFIG.SERVER_URL+'leadership/delete/1000').then((res) => {
        dispatch({
            type: UPDATE_ITEM_USERS,
            payload: res.data.data,
            id
        })
    }).catch((error)=> {
        toast.error("Something went wrong");
    })
}

export const checkDuplicate = async (data, cb) => {
    console.log("checkDuplicate",data)
   
    const formData = new FormData();

  
    
   
    formData.append(
        "name",
        data.name
    );
    formData.append(
        "value",
        data.value
    );
    formData.append(
        "id",
        data.id
    );

   
    await axios.post(CONFIG.SERVER_URL+'users/checkduplicate', formData).then((res) => {
       cb(res.data)
        
    }).catch((error)=> {
        toast.error("Something went wrong");
    })
}



export const checkPassword = async (data, cb) => {
    console.log("checkPassword",data)
   
    const formData = new FormData();

  
    
   
    formData.append(
        "password",
        data.password
    );

   
    await axios.post(CONFIG.SERVER_URL+'users/checkpassword', formData).then((res) => {
       cb(res.data)
        
    }).catch((error)=> {
        toast.error("Something went wrong");
    })
}

export const updatePassword = (data) => (dispatch) => {
    console.log("updatePassword",data)
    const formData = new FormData();

    // Update the formData object 
    
    formData.append(
        "password",
        data.oldpassword
    );
    formData.append(
        "newpassword",
        data.newpassword
    );
    
    axios.post(CONFIG.SERVER_URL+'users/updatepassword', formData).then((res) => {
        if(res.data.status == true){
            toast.success(res.data.msg);
        }else{
            toast.error(res.data.msg);
        }        
        
    }).catch((error)=> {
        toast.error("Something went wrong");
    })
}
