import {
    GET_LIST_MILESTONE,
    ADD_ITEM_MILESTONE,
    DELETE_ITEM_MILESTONE,
    UPDATE_ITEM_MILESTONE
} from '../actions/MilestonesActions'

const initialState = {
    milestonelist: []  
}

const MilestonesReducer = function (state = initialState, action) {    
    console.log("action", action);
    switch (action.type) {
        case GET_LIST_MILESTONE: {            
            return {
                ...state,
                milestonelist: [...action.payload],
            }
        }   
        case ADD_ITEM_MILESTONE: {                  
            state.milestonelist.push(action.payload); 
            state.milestonelist.sort((a,b) => {                
                return a.ordering - b.ordering
            })           
            return {
                ...state                
            }            
        }  
        case DELETE_ITEM_MILESTONE: {
            const index = state.milestonelist.findIndex((item) => {
                return item.id == action.id
            })
            if(index >= 0){
                state.milestonelist.splice(index, 1);
            }  
            return {
                ...state                
            }            
        }       
        case UPDATE_ITEM_MILESTONE: {
            const index = state.milestonelist.findIndex((item) => {
                return item.id == action.id
            })
            
            if(index >= 0){
                state.milestonelist.splice(index, 1);
            }  
           
            state.milestonelist.push(action.payload); 
            state.milestonelist.sort((a,b) => {                
                return a.ordering - b.ordering
            }) 
            return {
                ...state                
            }            
        }        
        default: {
            return {
                ...state,
            }
        }
    }
}

export default MilestonesReducer
