import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios'
import CONFIG from "../../../config.json";
export const GET_LIST_ENQ = 'GET_LIST_ENQ'
export const getData = () => (dispatch) => {
    axios.get(CONFIG.SERVER_URL+'enquiryData').then((res) => {
        dispatch({
            type: GET_LIST_ENQ,
            payload: JSON.parse(res.data.data),
        })
    })

}

