import React from 'react'

const newsRoutes = [
   
    
    {
        path: '/enquiries',
        component: React.lazy(() => import('./Index')),
    } 
]

export default newsRoutes
