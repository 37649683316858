import {
    GET_GALLERY_LIST,
    ADD_GALLERY,
    DELETE_GALLERY
} from '../actions/GalleryActions'

const initialState = {    
    Gallery: []
}

const GalleryReducer = function (state = initialState, action) {    
    console.log("action", action);
    switch (action.type) {
       
        case GET_GALLERY_LIST: {            
            return {
                ...state,
                Gallery: [...action.payload],
            }
        }   
        case ADD_GALLERY: {   
            console.log("action.payload", action.payload);
            action.payload.map((item) => {
                state.Gallery.push(item);            
            })
            return {
                ...state                
            }            
        }  
        case DELETE_GALLERY: {
            const index = state.Gallery.findIndex((item) => {
                return item.id == action.id
            })
            if(index >= 0){
                state.Gallery.splice(index, 1);
            }  
            return {
                ...state                
            }            
        }         
        default: {
            return {
                ...state,
            }
        }
    }
}

export default GalleryReducer
