import { authRoles } from './auth/authRoles'
import CONFIG from 'app/../config.json'


export const navigations = [
    {
        name: 'Dashboard',
        path: '/dashboard',
        icon: 'dashboard',
        id : "DASHBOARD"
    },
    {
        name: 'Leaderships',
        path: '/leadership',
        icon: 'dashboard',
        id : CONFIG.MODULE.LEADERSHIP
    },
    {
        name: 'Milestones',
        path: '/milestones',
        icon: 'dashboard',
        id : CONFIG.MODULE.MILESTONE
    },
    {
        name: 'Client Testimonials',
        path: '/testimonials',
        icon: 'dashboard',
        id : CONFIG.MODULE.TESTIMONIAL
    },
    {
        name: 'News',
        path: '/news',
        icon: 'dashboard',
        id : CONFIG.MODULE.NEWS
    },
    {
        name: 'Careers',        
        icon: 'dashboard',
        children: [
            {
                name: 'Jobs',
                iconText: 'SI',
                path: '/career',
            },
            {
                name: 'Job Applications',
                iconText: 'SI',
                path: '/career/applications',
            },
            {
                name: 'Gallery',
                iconText: 'SI',
                path: '/career/gallery',
            },
          
        ],
        id : CONFIG.MODULE.CAREER
    },
    {
        name: 'Services',
        icon: 'surround_sound',
        children: [
            {
                name: 'Services ',
                iconText: 'SI',
                path: '/services',
            },
            // {
            //     name: 'Sub Services',
            //     iconText: 'SI',
            //     path: '/services/items',
            // },
            {
                name: 'Services Content',
                iconText: 'SI',
                path: '/services/content',
            },
          
        ],
        id : CONFIG.MODULE.SERVICES
    },
    {
        name: 'Media',
        path: '/media',
        icon: 'dashboard',
        id : CONFIG.MODULE.MEDIA
    },
    {
        name: 'Blog',
        icon: 'surround_sound',
        children: [
            // {
            //     name: 'Category ',
            //     iconText: 'SI',
            //     path: '/blog/category',
            // },
            {
                name: 'Posts',
                iconText: 'SI',
                path: '/blog/post',
            },
          
        ],
        id : CONFIG.MODULE.BLOG
    },
    {
        name: 'Users',
        icon: 'surround_sound',
        path: '/users',
        id : CONFIG.MODULE.USERS
    },
     {
        name: 'Invoice',
        icon: 'surround_sound',
        children: [
            {
                name: 'Manage Invoice ',
                iconText: 'SI',
                path: '/invoice',              
            },
            {
                name: 'Manage Clients',
                iconText: 'SI',
                path: '/clients',
            },
          
        ],
        id : CONFIG.MODULE.INVOICE
    },
    {
        name: 'Conversion',
        icon: 'surround_sound',
        path: '/conversion',
        id : CONFIG.MODULE.INVOICE
    },
    {
        name: 'Enquiries',
        icon: 'surround_sound',
        path: '/enquiries',
        id : CONFIG.MODULE.NEWS
    },
    // {
    //     name: 'Invoice',
    //     icon: 'surround_sound',
    //     path: '/invoice',
    //     id : CONFIG.MODULE.INVOICE
    // },    
]
