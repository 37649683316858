import React from 'react'

const CareerRoutes = [
    {
        path: '/career/applications',
        component: React.lazy(() => import('./applications/Index')),
    } , 
    {
        path: '/career/add',
        component: React.lazy(() => import('./Add')),
    } ,  
    {
        path: '/career/update/:id',
        component: React.lazy(() => import('./Update')),
    } ,   
    
   
    {
        path: '/career/gallery',
        component: React.lazy(() => import('./Gallery')),
    } ,
    {
        path: '/career',
        component: React.lazy(() => import('./Index')),
    } ]

export default CareerRoutes
