import {
    GET_BLOG_CATEGORY,
    ADD_BLOG_CATEGORY,
    DELETE_BLOG_CATEGORY,
    UPDATE_BLOG_CATEGORY,
    GET_BLOG_POST,
    ADD_BLOG_POST,
    DELETE_BLOG_POST,
    UPDATE_BLOG_POST
} from '../actions/BlogActions'

const initialState = {
    Category: [],
    Posts : []
}

const BlogReducer = function (state = initialState, action) {    
    console.log("action", action);
    switch (action.type) {
        case GET_BLOG_CATEGORY: {            
            return {
                ...state,
                Category: [...action.payload],
            }
        }   
        case ADD_BLOG_CATEGORY: {     
            console.log("action.payload", action.payload)             
            console.log("state.Category", state.Category)             
            state.Category.push(action.payload);            
            console.log("state.Category after", state.Category)             
            return {
                ...state                
            }            
        }  
        case DELETE_BLOG_CATEGORY: {
            const index = state.Category.findIndex((item) => {
                return item.id == action.id
            })
            if(index >= 0){
                state.Category.splice(index, 1);
            }  
            return {
                ...state                
            }            
        }       
        case UPDATE_BLOG_CATEGORY: {
            const index = state.Category.findIndex((item) => {
                return item.id == action.id
            })
            console.log("indexe", index)
            console.log("before splice", state.Category)
            if(index >= 0){
                state.Category.splice(index, 1);
            }  
            console.log("after splice", state.Category)
            state.Category.push(action.payload); 
            console.log("after push", state.Category)
            return {
                ...state                
            }            
        }  
        case GET_BLOG_POST: {            
            return {
                ...state,
                Posts: [...action.payload],
            }
        }   
        case ADD_BLOG_POST: {     
            console.log("action.payload", action.payload)             
            console.log("state.Posts", state.Posts)             
            state.Posts.push(action.payload);            
            console.log("state.Posts after", state.Posts)             
            return {
                ...state                
            }            
        }  
        case DELETE_BLOG_POST: {
            const index = state.Posts.findIndex((item) => {
                return item.id == action.id
            })
            if(index >= 0){
                state.Posts.splice(index, 1);
            }  
            return {
                ...state                
            }            
        }       
        case UPDATE_BLOG_POST: {
            const index = state.Posts.findIndex((item) => {
                return item.id == action.id
            })
            console.log("indexe", index)
            console.log("before splice", state.Posts)
            if(index >= 0){
                state.Posts.splice(index, 1);
            }  
            console.log("after splice", state.Posts)
            state.Posts.push(action.payload); 
            console.log("after push", state.Posts)
            return {
                ...state                
            }            
        }        
        default: {
            return {
                ...state,
            }
        }
    }
}

export default BlogReducer
